<script lang="ts" setup>
withDefaults(
  defineProps<{
    color?: string
    label?: string
    trueValue?: boolean | string
    falseValue?: boolean | string
  }>(),
  {
    color: 'primary',
    trueValue: true,
    falseValue: false,
  }
)

const modelValue = defineModel<boolean | string>()
</script>

<template>
  <v-switch
    v-model="modelValue"
    class="skr-switch"
    density="compact"
    hide-details
    inset
    :color="color"
    :true-value="trueValue"
    :false-value="falseValue"
  >
    <template v-if="label || $slots.label" #label>
      <slot name="label">
        <span>{{ label }}</span>
      </slot>
    </template>
  </v-switch>
</template>

<style lang="scss" scoped>
.skr-switch {
  :deep(.v-input__control) {
    .v-switch__track {
      background-color: currentColor;
      opacity: 0.2;
      max-height: 24px;
    }

    .v-switch__thumb {
      color: inherit;
      transform: none;
      height: 18px;
      width: 18px;
    }

    .v-selection-control--dirty {
      .v-switch__thumb {
        background-color: currentColor;
      }
    }
  }
}
</style>
